import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppIcon from './AppIcon';
import '../App.css';

function HomePage(props) {
  // Media query to detect mobile or tablet
  const isMobileOrTablet = window.matchMedia("(max-width: 768px)").matches;
  let limit = 7;
    if (isMobileOrTablet) {
      limit = 2;
    }

  const [loading, setLoading] = useState(true);
  const [chunks, setChunks] = useState({});

  useEffect(() => {
    // URL of the JSON file hosted on CloudFront
    const url = 'https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/be-apps.json';
    // Fetch the JSON file
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        // setData(data);
        setLoading(false);
        const chunked = {};
        let index = 0;
        let counter = 0;

        while (index < data.length) {
          const chunk = data.slice(index, index + limit);
          chunked[counter] = chunk;
          index += limit;
          counter += 1;
        }

        setChunks(chunked);
      })
      .catch(error => {
        console.error('Fetching error:', error);
        setLoading(false);
      });
  }, [limit]);


  if (loading) {
    return <div></div>;
  }

  return (
    <div className="main">
      <div className="homepage lg-only layout-column center-center">
        <div className="headline layout-row center-left">
          <div className="titles layout-column left-center">
            <p className="title display hollow">YOUR NEW</p>
            <p className="title display white">ESSENTIAL APPS</p>
            <p className="title header-2 white sub-title">All the apps you ever needed are now in one place. Easy to use, and beautifully designed. Get all our apps in one single subscription.</p>
            <Link className="google-play" to="https://play.google.com/store/apps/dev?id=4642277398456551127" target="_blank">
              <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/google_play.png"/>
            </Link>
          </div>
          <img alt='' className="right-background" src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/background_desktop.jpg"/>
        </div>
        {Object.keys(chunks).map((i, chunk) => (
          <div key={i} className='app-icons layout-row center-center'>
            {chunks[i].map((app) => (
              < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
            ))}
          </div>
        ))}
      </div>
      <div className="homepage sm-only layout-column center-center">
        <div className="titles layout-column center-center">
          <img alt='' className="title" src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/title_mobile.png"/>
          <p className="title regular white sub-title">All the apps you ever needed are now in one place. No ads, easy to use, and beautifully designed. Get all our apps in one single subscription.</p>
          <Link className="google-play" to="https://play.google.com/store/apps/dev?id=4642277398456551127" target="_blank">
            <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/google_play.png"/>
          </Link>
        </div>
        <div className="line-separator-dashed"></div>
        <div className="all-apps">
        {Object.keys(chunks).map((i, chunk) => (
          <div key={i} className='app-icons layout-row center-center'>
            {chunks[i].map((app) => (
              < AppIcon key={app.name.replaceAll(' ', '')} app={app} eventLog={props.eventLog}/>
            ))}
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
