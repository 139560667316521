import { Link } from 'react-router-dom';
import '../App.css';

function AppIcon(props) {
  const app = props.app;
  return (
    <div className="app-icon layout-row center-center">
      <Link className="app-item layout-column center-center"
      to={`https://play.google.com/store/apps/details?id=${app.package_name}&referrer=utm_source%3Dlanding_page%26utm_medium%3Dlanding_page%26utm_campaign%3Dfrom_landing_page`}
      target="_blank" onClick={(e) => {
        e.stopPropagation();
        props.eventLog({'ga': 'download', 'fb': 'ViewContent', 'tt': 'ClickToDownload'}, app.name.replaceAll(' ', '_').toLowerCase());
      }}>
        <img alt='' src={app.image}/>
        <p className="header-2 white">{app.name}</p>
      </Link>
    </div>
  );
}

export default AppIcon;
