import axios from 'axios';
import React, { useState, useCallback, useRef } from 'react';
import '../App.css';
import OptionRadio from './OptionRadio';

const KlaviyoPopup = React.memo((props) => {
  const [showDone, setShowDone] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);

  const inputRef = useRef(null);

  const FirstPage = () => (
      <form className="register-email-form layout-column center-center"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setError('');
          let klaviyoBody = {
            type: 'profile',
            attributes: {
              email: emailValue
            }
           };
          // Create Klaviyo User
          axios.post(
            'https://jc0boleygj.execute-api.us-east-1.amazonaws.com/default/BeautifulEssentialsKlaviyoServer', JSON.stringify(klaviyoBody))
          .then(response => {
              const resData = JSON.parse(JSON.stringify(response.data));
              if (resData && resData.statusCode && resData.statusCode > 200) {
                if (resData.statusCode === 409) {
                  return setError('This email address is already taken. Please try another one.');
                }
                return setError(resData.body);
              }
              props.eventLog({'ga': 'enter_email', 'fb': 'CompleteRegistration', 'tt': 'AddToCart'}, 'Klaviyo');
              setShowDone(true);
            })
          .catch(err => setError(JSON.stringify(err)))
        }}>
        <p className="header-1 bold">Stay in the loop!</p>
        <p className="regular">Sign up for exclusive app updates and be the first to know about exciting features, offers, and new apps.</p>
        <input type="text" name="email" autoFocus="autoFocus" value={emailValue} placeholder="Your email" required onChange={useCallback((event) => {
          event.stopPropagation();
          event.preventDefault();
          const newValue = event.target.value;
          setEmailValue(newValue);
          // Calculate the cursor position
          const cursorPosition = event.target.selectionStart;
          // Preserve cursor position
          if (inputRef.current) {
            setTimeout(() => {
              inputRef.current.selectionStart = cursorPosition;
              inputRef.current.selectionEnd = cursorPosition;
            }, 0);
          }
        }, [])} ref={inputRef}></input>
        <div className="consent-check layout-row center-center">
        <OptionRadio checked={checked} setChecked={setChecked} disabled={""} name={`By clicking on "Sign me up" you acknowledge that you have read and agreed to our <a class="regular" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="regular" href="/privacy-policy" target="_blank">Privacy Policy</a>.`} dataKey={'consent'}/>
        </div>
        <input type="submit" name="submit-email" className="button raised pink" disabled={!checked} value="Sign me up" />
        <p className="error-info remark bold">{error}</p>
      </form>
  );

  const SecondPage = () => (
    <div className="after-register-popup layout-column center-center">
          <p className="header-1 bold">Thanks for signing up!</p>
    </div>
  );

  return (
    <div className={`enter-email-background${props.showKlaviyo ? ' visible' : ''}`}>
      <div className="enter-email layout-column">
        <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/close_popup.png" className="close-popup"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            document.body.style.overflow = "auto";
            document.body.style.position = 'static';
            document.body.style.width = 'auto';
            props.setShowKlaviyo(false);
          }}></img>
          <div className="banner">
            <img alt='' src="https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/popup_banner.jpg"></img>
          </div>
        { showDone ? <SecondPage /> : <FirstPage /> }
      </div>
    </div>
  );
});

export default KlaviyoPopup;
